import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Routes, RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { CustomFormsModule } from "ng2-validation";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./auth/login/login.component";
import { ObsModalComponent } from "./component/obs-modal/obs-modal.component";
import { HeaderComponent } from "./shared/header/header.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { SidebarComponent } from "./shared/sidebar/sidebar.component";
import { LoaderComponent } from "./component/loader/loader.component";

import { ICOInterceptor, ErrorInterceptor } from "./interceptors";
import { AppService } from "./services/app.service";
import { DataService } from "./services/data.service";
import { AdminService } from "./services/admin.service";

import { CookieModule } from "ngx-cookie";
import { ToastrModule } from "ngx-toastr";
import { FormsModule } from "@angular/forms";
import { ClipboardModule } from "ngx-clipboard";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ModalModule } from "ngx-bootstrap/modal";

//guards
import { AuthGuard } from "./guards/auth.guard";
import { DeGuard } from "./guards/deactivate.guard";

import { NumbersOnlyDirective } from "./directives/numbersOnly.directive";

const routes: Routes = [

    { path: '',  redirectTo: '/dashboard/application-data',  pathMatch: 'full' } ,
    
    { 
        path: 'login', 
        component: LoginComponent, 
        canActivate : [DeGuard] 
    } ,

    { 
        path: 'dashboard', 
        loadChildren: './pages/dashboard/dashboard.module#DashboardModule' , 
        canActivate : [AuthGuard] 
    },
    
    { 
        path: 'subscription-plan', 
        loadChildren: './pages/subscription-plan/subscription-plan.module#SubscriptionPlanModule' , 
        canActivate : [AuthGuard] 
    },

    { 
        path: 'standard-service', 
        loadChildren: './pages/standard-service/standard-service.module#StandardServiceModule' , 
        canActivate : [AuthGuard] 
    },

    { 
        path: 'payasyougo', 
        loadChildren: './pages/payasyougo/payasyougo.module#PayasyougoModule' , 
        canActivate : [AuthGuard] 
    },

    { 
        path: 'sales-and-vouchers', 
        loadChildren: './pages/sales-and-vouchers/sales-and-vouchers.module#SalesAndVouchersModule' , 
        canActivate : [AuthGuard] 
    },
    
    { 
        path: 'admin-credit', 
        loadChildren: './pages/admin-credit/admin-credit.module#AdminCreditModule' , 
        canActivate : [AuthGuard] 
    },
    
    { 
        path: 'standard-checks', 
        loadChildren: './pages/standard-checks/standard-checks.module#StandardChecksModule' , 
        canActivate : [AuthGuard] 
    },

    { 
        path: 'stats', 
        loadChildren: './pages/stats/module#StatsModule' , 
        canActivate : [AuthGuard] 
    },

    { 
        path: 'tax', 
        loadChildren: './pages/tax/tax.module#TaxModule' , 
        canActivate : [AuthGuard] 
    },

    { 
        path: 'currency', 
        loadChildren: './pages/currency-conversion/currency-conversion.module#CurrencyConversionModule' , 
        canActivate : [AuthGuard] 
    },

    { 
        path: 'invoices', 
        loadChildren: './pages/invoices/invoices.module#InvoicesModule' , 
        canActivate : [AuthGuard] 
    },

    { 
        path: 'extra-credit', 
        loadChildren: './pages/extra-credit/extra-credit.module#ExtraCreditModule' , 
        canActivate : [AuthGuard] 
    },

    { 
        path: 'stp-config', 
        loadChildren: './pages/stp-config/stp-config.module#StpConfigModule' , 
        canActivate : [AuthGuard] 
    },
    {
        path: "lang",
        loadChildren: "./pages/lang/lang.module#LangModule",
        canActivate: [AuthGuard],
    },  
    {
        path: "theme",
        loadChildren: "./pages/theme/theme.module#ThemeModule",
        canActivate: [AuthGuard],
    },
    {
        path: "email",
        loadChildren: "./pages/email/email.module#EmailModule",
        canActivate: [AuthGuard],
    },

    { 
        path: 'stp-pending-review', 
        loadChildren: './pages/stp-pending-review/module#StpPendingReviewModule' , 
        canActivate : [AuthGuard] 
    },

    {
        path: "pay-as-you-go",
        loadChildren: "./pages/pay-as-you-go/pay-as-you-go.module#PayAsYouGoModule",
        canActivate: [AuthGuard],
    },

    {
        path: "licence",
        loadChildren: "./pages/licence/licence.module#LicenceModule",
        canActivate: [AuthGuard],
    },

    {
        path: "user-licence",
        loadChildren: "./pages/user-licence/user-licence.module#UserLicenceModule",
        canActivate: [AuthGuard],
    },

    {
        path: "client-verification",
        loadChildren: "./pages/client-verification/client-verification.module#ClientVerificationModule",
        canActivate: [AuthGuard],
    },

    {
        path: "pricing-history",
        loadChildren: "./pages/pricing-history/pricing-history.module#PricingHistoryModule",
        canActivate: [AuthGuard],
    },

    {
        path: "assign-plan",
        loadChildren: "./pages/assign-plan/assign-plan.module#AssignPlanModule",
        canActivate: [AuthGuard],
    },

    {
        path: "deduction-history",
        loadChildren: "./pages/deduction-history/deduction-history.module#DeductionHistoryModule",
        canActivate: [AuthGuard],
    }
] ;

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		ObsModalComponent,
		HeaderComponent,
		FooterComponent,
		SidebarComponent,
		LoaderComponent,
		NumbersOnlyDirective,
	],
	imports: [
		HttpClientModule,
		BrowserModule,
		ClipboardModule,
		NgSelectModule,
		FormsModule,
		BrowserAnimationsModule,
		InfiniteScrollModule,
		// AppRoutingModule ,
		ToastrModule.forRoot({
			timeOut: 5000,
			positionClass: "toast-top-right",
			preventDuplicates: true,
			closeButton: true,
		}),
		ModalModule.forRoot(),
		CookieModule.forRoot(),
		RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'top'
          }),
		CustomFormsModule,
	],
	entryComponents: [ObsModalComponent],
	providers: [
		AuthGuard,
		DeGuard,
		AppService,
		DataService,
		AdminService,
		{ provide: HTTP_INTERCEPTORS, useClass: ICOInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		// { provide: "API_URL", useValue: "https://api.dev.kychub.com" }, //dev
		// { provide: "API_URL", useValue: "https://api.stage.kychub.com" }, //stage
		// { provide: "API_URL", useValue: 'https://api.kychub.com' }, //prod,
        { provide: "API_URL", useValue: "https://api-uat-in.kychub.com" }, //uat
       
		{
			provide: "SaleContract_Address",
			useValue: "0x98cba533acf087a01b3012f5532f717f5be0333e",
		},
		{ provide: "fromBlock", useValue: 4278460 },
		{
			provide: "config",
			useValue: {
				animated: true,
				keyboard: false,
				backdrop: true,
				ignoreBackdropClick: true,
				class: "custombox-container-fadein modal-lg",
			},
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
